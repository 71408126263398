var Yabawt = {};

Yabawt.generateDrpConfiguration = function(options){
    return {
        "ranges": {
            "Aujourd'hui": [
                moment(),
                moment()
            ],
            "Hier": [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days')
            ],
            "7 derniers jours": [
                moment().subtract(6, 'days'),
                moment()
            ],
            "30 derniers jours": [
                moment().subtract(29, 'days'),
                moment()
            ],
            "Ce mois": [
                moment().startOf('month'),
                moment().endOf('month')
            ],
            "Le mois dernier": [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')
            ]
        },
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Appliquer",
            "cancelLabel": "Annuler",
            "fromLabel": "Du",
            "toLabel": "Au",
            "customRangeLabel": "Personnalisé",
            "weekLabel": "W",
            "daysOfWeek": [
                "Di",
                "Lu",
                "Ma",
                "Me",
                "Je",
                "Ve",
                "Sa"
            ],
            "monthNames": [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            "firstDay": 1
        },
        "alwaysShowCalendars": true
    };
};