(function(){
    var a = function(options){
        var z = this;
        this.rel = options.selector;
        
        
        this.drp = null;
        this.useRange = false;
        
        this.registered = [];
        
        this.ready = false;
        this.onReadyFunc = [];
        
        
        $(function(){
            z.domReady();
        });
    };
    
    a.prototype.domReady = function(){
        var z = this;
        this.rel = $(this.rel);
        
        if(this.rel.length == 0)
            return;
        
        
        this.viewPanel = this.rel.find('.date-render-container');
        this.dateInfos = this.rel.find('.date-render-container .date-infos');
        this.rangeInfos = this.rel.find('.date-render-container .range-infos');
        
        var input = this.rel.find('input[name="main-date-range-picker"]');
        
        input.daterangepicker({
            "template":$('#daterangepicker-template'),
            "ranges": {
                "Aujourd'hui": [
                    moment(),
                    moment()
                ],
                "Hier": [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days')
                ],
                "7 derniers jours": [
                    moment().subtract(6, 'days'),
                    moment()
                ],
                "30 derniers jours": [
                    moment().subtract(29, 'days'),
                    moment()
                ],
                "Ce mois": [
                    moment().startOf('month'), 
                    moment().endOf('month')
                ],
                "Le mois dernier": [
                    moment().subtract(1, 'month').startOf('month'), 
                    moment().subtract(1, 'month').endOf('month')
                ]
            },
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Appliquer",
                "cancelLabel": "Annuler",
                "fromLabel": "Du",
                "toLabel": "Au",
                "customRangeLabel": "Personnalisé",
                "weekLabel": "W",
                "daysOfWeek": [
                    "Di",
                    "Lu",
                    "Ma",
                    "Me",
                    "Je",
                    "Ve",
                    "Sa"
                ],
                "monthNames": [
                    "Janvier",
                    "Février",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Aout",
                    "Septembre",
                    "Octobre",
                    "Novembre",
                    "Décembre"
                ],
                "firstDay": 1
            },
            "alwaysShowCalendars": true,
            "startDate": moment().subtract(7, 'days'),
            "endDate": moment()
        }, function (start, end, label) {
            z.onFieldChange();
        });
        input.on('show.daterangepicker', function(ev, picker) {
            z.getRangeField().prop('disabled', !z.useRange);
        });
        
        this.drp = input.data('daterangepicker');
        

        
        
        this.viewPanel.click(function () {
            if(!$(this).parents('#main-date-range-picker').is('.disabled'))
                z.drp.show();
        });
        
        
        // Bind des datas
        
        var data = {
            s: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            e: moment().format('YYYY-MM-DD'),
            r: '1'
        };
        
        if(localStorage.getItem('main-date-picker')){
            data = JSON.parse(
                localStorage.getItem('main-date-picker')
            );
        }
        
        this.drp.setStartDate(
            moment(data.s, 'YYYY-MM-DD').format('DD/MM/YYYY')
        );
        this.drp.setEndDate(
            moment(data.e, 'YYYY-MM-DD').format('DD/MM/YYYY')
        );
        
        this.getRangeField().val(data.r);
        this.bindData();
        
        
        for(var i = 0; i < this.onReadyFunc.length ; i++){
            var func = this.onReadyFunc[i];
            func();
        }
        this.ready = true;
        
        setTimeout(function(){
            z.onFieldChange();
        },200)
    };
    a.prototype.onFieldChange = function(){        
        this.bindData();
        
        // Persistance
        if(localStorage){
            localStorage.setItem('main-date-picker', JSON.stringify({
                s : this.drp.startDate.format('YYYY-MM-DD'),
                e : this.drp.endDate.format('YYYY-MM-DD'),
                r : this.getRangeField().val()
            }));
        }
        
        
        var data = {
            startDate : this.drp.startDate,
            endDate : this.drp.endDate,
            range : this.getRangeField().val()
        };
        
        $(this.registered).each(function(){
            if (typeof this.func === 'function'){
                this.func(data);
            }
        });
    };
    
    a.prototype.bindData = function () {
        var format = 'D MMM YYYY';
        var start = moment(this.drp.startDate).format(format);
        var end = moment(this.drp.endDate).format(format);;
        var range = this.getRangeField().val();
        
        this.dateInfos.text(
            start + ' - ' + end
        );
        this.rangeInfos.text(
            (range == '1' ? 'Jour' :
            range == '7' ? 'Semaine' :
            'Mois')
        );
    };
    
    
    a.prototype.register = function(cb, useRange){
        var z = this;
        
        this.rel.removeClass('disabled');
            
        if(useRange){
            this.useRange = true;
            $(function(){
                z.rel.find('.range-infos').show();
            });
        }
        
        this.registered.push({
            func : cb,
            useRange : useRange
        });
    };
    
    a.prototype.getRangeField = function(){
        return this.drp.container.find('#range');
    };
    
    a.prototype.onReady = function(func){
        if(this.ready){
            func();
        }else{
            this.onReadyFunc.push(func);
        }
    };
    
    
    
    window.MainDatePicker = new a({
        selector: '#main-date-range-picker'
    });
})();